import { Trans, t } from "@lingui/macro";
import { Box, Grid, Stack, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faRankingStar } from "@fortawesome/pro-light-svg-icons";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons";
import CardColor from "../../components/CardColor";
import Container from "../../components/Container";
import Header from "../../components/Header";
import Seo from "../../components/seo";
import SecondaryButton from "../../components/SecondaryButton";
import HeroLanding from "../../components/HeroLanding";
import ProductModal from "../../components/ProductModal";
import { theme } from "../../themeV2";
import ArrowButton from "../../components/ArrowButton";
import LandingsTabs from "../../components/landings/LandingsTabs";
import ImageComponent from "../../components/landings/ImageComponent";
import DefaultSection from "../../components/landings/DefaultSection";
import heroImg from "../../images/segments/banks/hero.png";
import logo1 from "../../images/segments/banks/logo-big.png";
import logo2 from "../../images/segments/banks/logo-enbd.png";
import logo3 from "../../images/segments/banks/logo-andbank.png";
import logo4 from "../../images/segments/banks/logo-unicorp.png";
import sec2Img from "../../images/segments/banks/sec-2.png";
import sec3Img from "../../images/segments/insurance-pensions/our-offer.png";
import sec3GreenIcon from "../../images/segments/insurance-pensions/icon-green.png";
import sec3OrangeIcon from "../../images/segments/insurance-pensions/icon-orange.png";
import sec3BlueIcon from "../../images/segments/insurance-pensions/icon-blue.png";
import sec4Img from "../../images/segments/banks/sec-4.png";
import sec5Tab1Img from "../../images/segments/banks/sec-5_tab-1.png";
import sec5Tab2Img from "../../images/segments/banks/sec-5_tab-2.png";
import sec5Tab3Img from "../../images/segments/banks/sec-5_tab-3.png";
import sec6Img from "../../images/segments/banks/sec-6.png";
import sec6Icon1 from "../../images/segments/insurance-pensions/fund-research_icon-1.png";
import sec6Icon2 from "../../images/segments/insurance-pensions/fund-research_icon-2.png";
import sec6Icon3 from "../../images/segments/insurance-pensions/fund-research_icon-3.png";
import sec7Img from "../../images/segments/banks/sec-7.png";
import sec7Yellow from "../../images/segments/banks/icon-yellow.svg";
import sec7Green from "../../images/segments/banks/icon-green.svg";
import sec7Purple from "../../images/segments/banks/icon-purple.svg";
import sec7Blue from "../../images/segments/banks/icon-blue.svg";
import sec8Img from "../../images/segments/banks/sec-8.png";
import sec9Blue from "../../images/segments/banks/icon-blue-2.svg";
import sec9Orange from "../../images/segments/banks/icon-orange.svg";

const mainColor = theme.palette.colors.purple.main;

const metadata = {
  title: "Banks & Wealth Managers",
  description: "Our comprehensive suite of digital wealth solutions caters to everyone, from individual investors to seasoned portfolio managers and financial institutions. We provide cutting-edge tools and services to optimize your investment strategies, enhance client engagement, and drive business growth.",
};

const CASE_STUDIES_ITEMS = [
  {
    img: logo1,
    title: t`Omnichannel platform`,
    description: <Trans>BIG has adopted a modular approach designed to encompass the <strong>entire relationship cycle</strong> between the company, its clients, and advisors. The project includes fund management and has evolved into a versatile multi-product platform.</Trans>,
  },
  {
    img: logo2,
    title: t`Regulatory Compliance`,
    description: <Trans>Emirates NBD needed to adapt its business model to meet new regulatory requirements. The bank introduced <strong>model portfolios combining funds and ETFs</strong> to offer diversified investment options to its end-clients, ensuring compliance and enhancing service offerings</Trans>,
  },
  {
    img: logo3,
    title: t`Broker Online`,
    description: <Trans>Andbank Spanish group sought a multi-region platform adaptable to both Broker Online and Private Banker solutions, capable of presenting <strong>end-client data, positions, order book, and trading capabilities</strong> based on user profiles.</Trans>,
  },
  {
    img: logo4,
    title: t`ESG Reporting`,
    description: <Trans>Using Allfunds’ nextportfolio tool, Unicorp provides <strong>detailed ESG profiles</strong> with SFDR classifications and taxonomy. ESG ratings from MainStreet Partners offer comprehensive insights, aiding clients in making informed investment decisions.</Trans>,
  },
];

const SEC_3_ITEMS = [
  {
    xs: 12,
    sm: 6,
    md: 4,
    icon: sec3GreenIcon,
    color: theme.palette.colors.green.main,
    title: t`Long Only Funds`,
    description: t`Our B2B platform supports an open-architecture model, featuring over 3,100 fund groups and approximately €1.5 trillion in Assets under Administration (AuA). With over 24 years of experience, we are a trusted leader in the industry.`,
  },
  {
    xs: 12,
    sm: 6,
    md: 4,
    icon: sec3BlueIcon,
    color: theme.palette.colors.blueLight.main,
    title: t`Private Markets Platform`,
    description: t`Launched in the first half of 2023, our open-architecture model for private markets includes more than 120 Alternative Asset Managers and over €16 billion in AuA.`,
  },
  {
    xs: 12,
    sm: 6,
    md: 4,
    icon: sec3OrangeIcon,
    color: theme.palette.colors.orangeLight.main,
    title: t`ETP platform`,
    description: t`Our one-stop shop solution for Exchange-Traded Products (ETPs) provides clients with access to a wide variety of ETPs, enhance liquidity delivery, and optimize distribution strategies.`,
  },
];

const SEC_5_TABS_ITEMS = [
  {
    img: sec5Tab1Img,
    title: "ESG Analytics",
    description: (
      <Stack spacing={2}>
        <Typography><Trans>
          A comprehensive ESG audit tool to assess portfolios of Funds, ETFs, Equities, Corporate Bonds, Government Bonds,
          Green and Social Bonds.
        </Trans></Typography>
        <Box
          display="grid"
          columnGap={3}
          rowGap={1}
          width={{ xs: "100%", sm: "fit-content" }}
          gridTemplateColumns={{ xs: "1fr 1fr", sm: "repeat(4, 1fr)", md: "1fr 1fr" }}
        >
          <Stack justifyContent="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: mainColor, fontSize: "24px" }} />
              <Typography style={{ color: mainColor, fontSize: "24px", fontWeight: 700 }}>+90.000</Typography>
            </Stack>
            <Typography><Trans>Funds & ETFs</Trans></Typography>
          </Stack>
          <Stack justifyContent="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: mainColor, fontSize: "24px" }} />
              <Typography style={{ color: mainColor, fontSize: "24px", fontWeight: 700 }}>415</Typography>
            </Stack>
            <Typography><Trans>Funds Houses</Trans></Typography>
          </Stack>
          <Stack justifyContent="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: mainColor, fontSize: "24px" }} />
              <Typography style={{ color: mainColor, fontSize: "24px", fontWeight: 700 }}>4.400</Typography>
            </Stack>
            <Typography><Trans>Green Bonds</Trans></Typography>
          </Stack>
          <Stack justifyContent="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: mainColor, fontSize: "24px" }} />
              <Typography style={{ color: mainColor, fontSize: "24px", fontWeight: 700 }}>8.500</Typography>
            </Stack>
            <Typography><Trans>Unique Strategies</Trans></Typography>
          </Stack>
        </Box>
      </Stack>
    ),
  },
  {
    img: sec5Tab2Img,
    title: "ESG Portfolio and Advisory",
    description: (
      <Stack spacing={1}>
        <Typography><Trans>
          Our comprehensive service encompasses
        </Trans></Typography>
        <ul>
          <li><Trans>Support in creating or restructuring ESG-focused products</Trans></li>
          <li><Trans>Assistance in drafting operational documents</Trans></li>
          <li><Trans>Delivery of monthly universes/portfolio reporting</Trans></li>
          <li><Trans>ESG training for your salesforce and end investors</Trans></li>
          <li><Trans>Commercial and marketing support, empowering you to excel in the ESG space.</Trans></li>
        </ul>
      </Stack>
    ),
  },
  {
    img: sec5Tab3Img,
    title: "ESG Reporting",
    description: (
      <Stack spacing={2}>
        <Typography><Trans>
          Deliver tailor-made fund and portfolio reports that showcase your corporate branding and style.
        </Trans></Typography>
        <Typography><Trans>
          Our comprehensive ESG section combines essential EET information, ESG ratings, Impact metrics, and PAI,
          ensuring your reports are ready for seamless distribution to end clients.
        </Trans></Typography>
      </Stack>
    ),
  },
];

const SEC_6_ITEMS = [
  {
    icon: sec6Icon1,
    title: <Trans>Comprehensive Due Diligence</Trans>,
    description: (
      <Trans>
        Gather in-depth information at both the fund and company levels. Make informed decisions with access
        to consistent and up-to-date data.
      </Trans>
    ),
  },
  {
    icon: sec6Icon2,
    title: <Trans>Proactive Monitoring</Trans>,
    description: (
      <Trans>
        Build and manage model portfolios aligned with various risk profiles. Receive personalized reporting
        and insights tailored to your needs.
      </Trans>
    ),
  },
  {
    icon: sec6Icon3,
    title: <Trans>Customized Model Portfolios</Trans>,
    description: (
      <Trans>
        Build and manage model portfolios aligned with various risk profiles. Receive personalized reporting and
        insights tailored to your needs.
      </Trans>
    ),
  },
];

const SEC_7_ITEMS = [
  {
    xs: 12,
    sm: 6,
    md: 6,
    icon: sec7Yellow,
    color: theme.palette.colors.yellow.main,
    title: t`Accelerated integration process`,
    description: t`Build new business lines by leveraging on 100+ pre integrated data vendors `,
  },
  {
    xs: 12,
    sm: 6,
    md: 6,
    icon: sec7Green,
    color: theme.palette.colors.green.main,
    title: t`One single entry point and support`,
    description: t`One place to invoke all what you need`,
  },
  {
    xs: 12,
    sm: 6,
    md: 6,
    icon: sec7Purple,
    color: mainColor,
    title: t`Multi asset`,
    description: t`Integration of any asset type in the market`,
  },
  {
    xs: 12,
    sm: 6,
    md: 6,
    icon: sec7Blue,
    color: theme.palette.colors.blueLight.main,
    title: t`Real-time connectivity`,
    description: t`Real-time data connectivity with multiple data sources`,
  },
];

const SEC_9_ITEMS = [
  {
    xs: 12,
    sm: 6,
    md: 4,
    icon: sec9Blue,
    color: theme.palette.colors.blueLight.main,
    title: t`Fund hosting and private labelling`,
    description: t`Our comprehensive fund hosting and private labelling offering establishes a robust and enduring relationship with fund houses and distributors, ensuring long-term collaboration and mutual growth through tailored solutions and dedicated support.`,
  },
  {
    xs: 12,
    sm: 6,
    md: 4,
    icon: sec9Orange,
    color: theme.palette.colors.orangeLight.main,
    title: t`Sub-Advisory Platform`,
    description: t`Allfunds accelerates your own Sub-Advisory developments thanks to customized services adapted to your needs: tailor-made mandates corresponding to your asset class and/or manager preferences, dedicated fund solutions.`,
  },
  {
    xs: 12,
    sm: 6,
    md: 4,
    icon: sec7Green,
    color: theme.palette.colors.green.main,
    title: t`Multi-Boutiques Offering`,
       description: t`This B2B offering from Allfunds is not limited to pre-selected boutique funds available off-the-shelf but rather to an investment solution that allows wealth managers to design and implement a differentiating investment offer for their clients.`,
  },
];

function Banks() {
  const [showModal, setShowModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(SEC_5_TABS_ITEMS[0].title);

  const renderRequestButton = () => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => setShowModal(true)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderArrowButton = (link) => {
    return (
      <ArrowButton
        isDark
        variant="text"
        color="secondary"
        title="Know more"
        extraStyles={{ px: "0 !important" }}
        contentColor={theme.palette.secondary.main}
        onClick={() => window.open(link, "_blank")}
      />
    );
  };

  const renderCard = ({ icon, color, title, description, xs, sm, md }) => {
    return (
      <Grid item xs={xs} sm={sm} md={md}>
        <CardColor mt={0} color={color} icon={icon} title={title} description={description} IsDarkMode={true} />
      </Grid>
    );
  };

  const selectedItem = SEC_5_TABS_ITEMS.find((item) => item.title === selectedTab);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Seo title={metadata.title} description={metadata.description} />
      <Header />
      <HeroLanding
        kind={t`Banks & Wealth Managers`}
        title={t`Empowering Growth and Efficiency`}
        description={
          <Trans>
            Our comprehensive suite of digital wealth solutions caters to
            everyone, <strong>from individual investors</strong> to{" "}
            <strong>seasoned portfolio managers</strong> and financial
            institutions. We provide cutting-edge tools and services to optimize
            your investment strategies, enhance client engagement, and drive
            business growth.
          </Trans>
        }
        grid={{
          columnLeft: { md: 7 },
          columnRight: { md: 5 },
        }}
        multimedia={heroImg}
        kindColor={mainColor}
        handleOnclickInfo={() => setShowModal(true)}
        buttonJustify="flex-start"
      />
      <Container component="section" pb={{ xs: 2, md: 10, lg: 8 }}>
        <Box
          width="fit-content"
          m={{ xs: "0 auto -90px", md: "0 auto -102.5px" }}
        >
          <CaseStudiesStack>
            <FontAwesomeIcon icon={faRankingStar} />
            <Typography>
              Case
              <br />
              Studies
            </Typography>
            <FontAwesomeIcon icon={faArrowDown} />
          </CaseStudiesStack>
        </Box>
        <Grid
          container
          spacing={3}
          sx={{
            backgroundColor: theme.palette.colors.white.dark,
            padding: "124px 24px 24px",
            marginLeft: "-16px!important",
            borderRadius: "14px",
          }}
        >
          {CASE_STUDIES_ITEMS.map(({ img, title, description }) => (
            <Grid item xs={12} sm={6} md={3}>
              <Stack spacing={2} component="article">
                <ImageComponent
                  src={img}
                  alt={title + " | Allfunds"}
                  imgFit="contain"
                  extraStyles={{
                    width: "164px",
                    height: 50,
                  }}
                  extraImgStyles={{
                    objectPosition: "left",
                    mixBlendMode: "multiply",
                  }}
                />
                <Stack spacing={1}>
                  <Typography fontWeight={700}>{title}</Typography>
                  <Typography color={theme.palette.colors.blue.light}>
                    {description}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
      <DefaultSection
        textAlign="center"
        pretitleAlign="center"
        pretitle={t`The platform for world-class banking and wealth management`}
        pretitleColor={mainColor}
        title={t`Transform Legacy Systems into Digital-First Experiences`}
        description={
          <>
            <Stack alignItems="center">
              <Typography width={{ md: "80%", lg: "70%" }}>
                <Trans>
                  Seamlessly manage the full lifecycle of wealth management on a
                  single platform with modern, user-friendly interfaces,
                  integrated technology, ESG and regulatory compliant solutions
                  to enhance digital experiences and optimize operations.
                </Trans>
              </Typography>
            </Stack>
            <Box pt={1} display="flex" justifyContent={{ xs: "center" }}>
              {renderRequestButton()}
            </Box>
            <Grid
              container
              xs={12}
              pt={{ xs: 5, sm: 7, md: 9 }}
              justifyContent="center"
              gap={{ xs: 4, md: 0 }}
              textAlign="initial"
            >
              <Grid item xs={12} sm={8} md={4} order={{ xs: 2, md: 1 }}>
                <Stack spacing={1} alignItems={{ md: "flex-end" }}>
                  <Stack spacing={0.5} alignItems={{ md: "flex-end" }}>
                    <Typography
                      fontSize={24}
                      lineHeight={1}
                      fontWeight={900}
                      color={theme.palette.colors.green.main}
                    >
                      01
                    </Typography>
                    <Typography fontWeight={700} fontSize="20px">
                      <Trans>For Advisors & Institutions</Trans>
                    </Typography>
                  </Stack>
                  <Stack
                    component="span"
                    width={66}
                    height="2px"
                    backgroundColor={theme.palette.colors.green.main}
                  ></Stack>
                  <Typography textAlign={{ md: "right" }}>
                    <strong>Equip the team</strong> by enhancing personal
                    relationships and advice with continually updated
                    information, utilizing our analytics and reporting tools to
                    allow advisors to tailor insights.
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                spacing={1}
                order={{ xs: 1, md: 2 }}
              >
                <Stack px={2} pb={2}>
                  <img
                    width="100%"
                    alt="The platform for world-class banking and wealth management"
                    src={sec2Img}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={8} md={4} order={3}>
                <Stack spacing={1}>
                  <Stack spacing={0.5}>
                    <Typography
                      fontSize={24}
                      lineHeight={1}
                      fontWeight={900}
                      textAlign={{ md: "left" }}
                      color={theme.palette.colors.blueLight.main}
                    >
                      02
                    </Typography>
                    <Typography
                      fontWeight={700}
                      fontSize="20px !important"
                      textAlign={{ md: "left" }}
                    >
                      <Trans>For Data Managers</Trans>
                    </Typography>
                  </Stack>
                  <Stack
                    component="span"
                    width={66}
                    height="2px"
                    backgroundColor={theme.palette.colors.blueLight.main}
                  ></Stack>
                  <Typography textAlign={{ md: "left" }}>
                    Centralize and optimize your investment data with our
                    comprehensive platform. Access a{" "}
                    <strong>single source for multi-asset data</strong> feeds,
                    streamline operations, reduce costs, and enhance
                    decision-making with real-time integration and flexible
                    delivery options.{" "}
                    <strong>
                      Ensure data consistency, security, and availability.
                    </strong>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={8} order={4}>
                <Stack spacing={1} alignItems={{ md: "center" }}>
                  <Stack spacing={0.5} alignItems={{ md: "center" }}>
                    <Typography
                      fontSize={24}
                      lineHeight={1}
                      fontWeight={900}
                      color={mainColor}
                    >
                      03
                    </Typography>
                    <Typography fontWeight={700} fontSize="20px !important">
                      <Trans>For Investors</Trans>
                    </Typography>
                  </Stack>
                  <Stack
                    component="span"
                    width={66}
                    height="2px"
                    backgroundColor={mainColor}
                  ></Stack>
                  <Typography textAlign={{ md: "center" }}>
                    Build tailored portfolios at scale with the ability to
                    create truly personalized, yet compliant and risk-centric
                    investment products{" "}
                    <strong>
                      according to each client’s lifestyle, beliefs, and life
                      stage,
                    </strong>{" "}
                    while efficiently managing the product palette offered to
                    investors across asset classes and their associated
                    recommendations and views.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </>
        }
        containerPy={0}
        pt={{ xs: 2, md: 10, lg: 8 }}
      />
      <DarkStack
        component="span"
        color={theme.palette.colors.white.main}
        mt={{ xs: 4, md: 14.5, lg: 10 }}
        borderRadius="48px 48px 0 0"
        backgroundColor={theme.palette.colors.blue.dark}
      >
        <Typography
          textAlign="center"
          fontWeight={700}
          fontSize={{ xs: "36px", sm: "48px" }}
          color={theme.palette.colors.white.main}
          mt={{ xs: 2, sm: 5 }}
        >
          <Trans>Our offer</Trans>
        </Typography>
        <DefaultSection
          textAlign="center"
          pretitleAlign="center"
          pretitle={t`Invest. Everything. Centralize`}
          pretitleColor={mainColor}
          title={
            <Trans>
              One platform for mutual funds, <br />
              private markets, and ETP distribution.
            </Trans>
          }
          description={
            <>
              <Grid container xs={12} justifyContent="center">
                <Grid
                  item
                  xs={3}
                  md={4}
                  pr={{ xs: 0.5, md: 2 }}
                  display="flex"
                  direction="column"
                  justifyContent="center"
                >
                  <Stack
                    spacing={1}
                    flexDirection="row"
                    flexWrap="nowrap"
                    alignItems="flex-start"
                    justifyContent="flex-end"
                  >
                    <Stack
                      alignItems="flex-end"
                      zIndex={2}
                      ml="-12px!important"
                    >
                      <Typography
                        fontSize={{ md: "28px" }}
                        lineHeight="1.1"
                        fontWeight={700}
                      >
                        <Trans>Long-Only</Trans>
                      </Typography>
                      <Typography>
                        <Trans>platform</Trans>
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                  px={{ xs: 0.5, md: 2 }}
                  pb={{ xs: 1, md: 2 }}
                >
                  <Stack>
                    <img width="100%" alt="Leading Platform" src={sec3Img} />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={3}
                  md={4}
                  pl={{ xs: 0.5, md: 2 }}
                  display="flex"
                  direction="column"
                  justifyContent="center"
                >
                  <Stack
                    spacing={1}
                    flexDirection="row"
                    flexWrap="nowrap"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <Stack zIndex={2} ml="-12px!important" textAlign="left">
                      <Typography
                        fontSize={{ md: "28px" }}
                        lineHeight="1.1"
                        fontWeight={700}
                      >
                        <Trans>Private Markets</Trans>
                      </Typography>
                      <Typography>
                        <Trans>platform</Trans>
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack alignItems="center">
                    <Stack alignItems="center" zIndex={2} mt="-20px !important">
                      <Typography
                        fontSize={{ md: "28px" }}
                        lineHeight="1.1"
                        fontWeight={700}
                      >
                        <Trans>New ETP</Trans>
                      </Typography>
                      <Typography>
                        <Trans>platform</Trans>
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Box pt={1}>
                <Grid
                  container
                  spacing={2}
                  textAlign="left"
                  justifyContent="center"
                >
                  {SEC_3_ITEMS.map((item, key) => renderCard({ ...item, key }))}
                </Grid>
              </Box>
            </>
          }
          buttons={renderRequestButton()}
          containerPy={0}
          pt={{ xs: 2, md: 5 }}
          pb={{ xs: 1, md: 10, lg: 8 }}
        />
        <DefaultSection
          pretitle={t`Wealth Manager Portal`}
          pretitleColor={mainColor}
          title={
            <Trans>
              Nextportfolio <br />
              360 solution for your advisors, bankers and end client networks.
            </Trans>
          }
          description={
            <Stack spacing={2}>
              <Typography>
                <Trans>
                  Newly enhanced and built in AI, Nextportfolio is the{" "}
                  <strong>
                    go-to portfolio management tool for wealth management
                    professionals and private banks.
                  </strong>
                </Trans>
              </Typography>
              <Typography>
                <Trans>
                  Delivering everything you need for planning and execution,
                  Nextportfolio offers ESG metrics, reporting solutions,
                  rebalancing, and other value-added functionalities to boost
                  your analysis capabilities and facilitate compliance and
                  regulatory requirements.
                </Trans>
              </Typography>
              <Typography>
                <Trans>
                  Leverage dedicated software to support your Advisory,
                  Discretionary Portfolio Management, Execution Only, and
                  Robo-Advisory services.
                </Trans>
              </Typography>
            </Stack>
          }
          multimedia={sec4Img}
          buttons={renderRequestButton()}
        />
        <DefaultSection
          textAlign="center"
          pretitleAlign="center"
          pretitle={t`ESG Solutions`}
          pretitleColor={mainColor}
          title={t`Convert ESG from a burden to an opportunity`}
          description={
            <>
              <Stack spacing={1} pb={1}>
                <Typography>
                  <Trans>
                    At Allfunds, ESG isn't just an afterthought - it's
                    seamlessly integrated into our suite of financial solutions.
                  </Trans>
                </Typography>
                <Typography>
                  <Trans>
                    We offer a comprehensive set of solutions, including
                    proprietary ESG ratings, data, and tailored solutions to
                    help you navigate the complex ESG landscape. Partner with us
                    and MainStreet Partners, our trusted ESG expert, to unlock a
                    world of sustainable investment opportunities and turn
                    challenges into competitive advantages.
                  </Trans>
                </Typography>
              </Stack>
              <LandingsTabs
                value={selectedTab}
                valueSet={setSelectedTab}
                tabs={SEC_5_TABS_ITEMS.map((item) => item.title)}
                tabColor={mainColor}
                tabBgColor={theme.palette.colors.purple.light}
              />
              <DefaultSection
                textAlign="left"
                padding="0!important"
                title={selectedItem.title}
                titleVariant="h3"
                description={selectedItem.description}
                multimedia={selectedItem.img}
              />
              <Box display="flex" justifyContent="center">
                {renderRequestButton()}
              </Box>
            </>
          }
        />
        <DefaultSection
          direction="row-reverse"
          grid={{
            columnRight: {
              md: 5,
            },
            columnLeft: {
              md: 7,
            },
          }}
          pretitle={t`Fund Research`}
          pretitleColor={mainColor}
          title={
            <Trans>
              Focus on What Matters: <br />
              Expert Investment Solutions
            </Trans>
          }
          description={
            <Stack spacing={2}>
              <Typography>
                <Trans>
                  Allfunds Investment Team empowers you to focus on your core
                  investment strategies while we handle the complexities of
                  research and due diligence. Our tailored, independent
                  solutions enhance your efficiency and support your ESG goals.
                </Trans>
              </Typography>
              <Typography fontSize="24px" fontWeight={700}>
                <Trans>Brief Description of Services</Trans>
              </Typography>
              <Grid container spacing={2} ml="-24px !important">
                {SEC_6_ITEMS.map(({ icon, title, description }) => (
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <img
                        width={48}
                        height={48}
                        src={icon}
                        alt={title}
                        style={{ objectFit: "contain" }}
                      />
                      <Typography fontWeight={700}>{title}</Typography>
                      <Typography>{description}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          }
          multimedia={sec6Img}
          buttons={renderRequestButton()}
        />
        <DefaultSection
          pretitle={t`Golden Data Hub`}
          grid={{
            columnRight: {
              md: 4,
            },
            columnLeft: {
              md: 8,
            },
          }}
          pretitleColor={mainColor}
          title={t`Simplify your data operations with a single source for all your investment data needs`}
          description={
            <Grid container spacing={2} ml="-24px !important">
              {SEC_7_ITEMS.map((item, key) => renderCard({ ...item, key }))}
            </Grid>
          }
          multimedia={sec7Img}
          buttons={renderRequestButton()}
        />
        <DefaultSection
          direction="row-reverse"
          pretitle={t`Due Diligence Portal`}
          pretitleColor={mainColor}
          title={t`Fast-track your Due Diligence workflow, from data to reporting`}
          description={
            <Typography>
              <Trans>
                Access accurate, up-to-date Due Diligence material from
                Allfunds’ standard database. Run assessments and generate
                regulation compliant reports that support your fund catalog.
              </Trans>
            </Typography>
          }
          multimedia={sec8Img}
          buttons={
            <>
              {renderRequestButton()}
              {renderArrowButton()}
            </>
          }
        />
        <DefaultSection
          textAlign="center"
          pretitleAlign="center"
          pretitle={t`ManCo Services`}
          pretitleColor={mainColor}
          title={t`Your Leading Platform for Mutual Fund Distribution`}
          description={
            <Stack spacing={2} alignItems="center">
              <Typography width={{ sm: "80%", md: "80%" }}>
                <Trans>
                  Allfunds Investment Solutions can support distributors in a
                  variety of services, reducing administrative load and enabling
                  them to broaden their commercial reach.
                </Trans>
              </Typography>
              <Grid
                container
                spacing={2}
                textAlign="left"
                justifyContent="center"
                marginLeft="-24px!important"
              >
                {SEC_9_ITEMS.map((item, key) => renderCard({ ...item, key }))}
              </Grid>
            </Stack>
          }
          buttons={renderRequestButton()}
        />
      </DarkStack>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Bank & Wealth Managers"
          product="bank-wealth-managers"
          hasTemplate={false}
        />
      )}
    </ThemeProvider>
  );
}

const CaseStudiesStack = styled(Stack)`
            width: 180px;
            height: 180px;
            font-size: 24px;
            position: relative;
            border-radius: 100%;
            justify-content: center;
            border: 12px solid #fff;
            color: ${theme.palette.colors.white.main};
            background-color: ${mainColor};
            ${theme.breakpoints.up("md")} {
            width: 205px;
            height: 205px;
            font-size: 36px;
          }

            p {
            font-weight: 600;
            line-height: 1.1;
            text-align: center;
            margin: 12px 0 8px 0;
                color: ${theme.palette.colors.white.main};
            ${theme.breakpoints.up("md")} {
            font-size: 24px;
          }
          }

            `;

const DarkStack = styled(Stack)`
  p:not(.preTitle) {color: ${theme.palette.colors.white.main};
`;

export default Banks;
